<template>
  <v-container fluid>
    <div class="d-flex mb-3" v-if="all">
      <v-alert type="info" class="ma-0" dense text> Estudio Nóminas </v-alert>
    </div>
    <v-card>
      <v-data-table
        fixed-header
        :items="nominas"
        :headers="nominasHeaders"
        class="fixed-checkbox"
        item-key="idNomina"
        :single-select="false"
        show-select
        :loading="loading"
        :footer-props="{
          showFirstLastPage: true,
          showCurrentPage: true,
            itemsPerPageOptions: [10, 25, 50, -1],
        }"
        checkbox-color="secondary"
      >
        <template v-slot:body.prepend="{ headers }">
          <table-filters
            :headers="headers"
            :items="nominas"
            v-model="inlineFilters"
          ></table-filters>
        </template>
      </v-data-table>
    </v-card>

      <div style="max-width: fit-content;position: fixed;right:20px; bottom: 20px;z-index:100000" bottom class="d-flex mt-2 ms-auto">
        <!-- <v-btn color="primary" fab @click="exportar" large class="me-4">
          <v-icon>mdi-microsoft-excel</v-icon>
        </v-btn> -->

        <v-spacer></v-spacer>
        <v-btn color="primary" fab v-if="$root.acceso('PERSONAS')" to="/nominas/nuevo" large>
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </div>
  </v-container>
</template>

<script>
import FileSaver from 'file-saver';
import * as ExcelJS from "exceljs";
import { parseDate, perColumnFilter } from "@/utils";

export default {
  components: {
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  data() {
    return {
      inlineFilters: { idNomina: this.$route.query.filter ? `=${this.$route.query.filter}` : null },
      nominas: [],
      loading: false,
    };
  },
  computed: {
    nominasHeaders() {
      return [
        // { text: "Acciones", value: "acciones", filterable: false, sortable: false,},
        // { text: "Entrada", value: "fechaReserva", dataType: "date",},
        // { text: "Horario", value: "nombreHorario", dataType: "select" },
        // { text: "Cliente", value: "datos.tipoCelebracion", tab: [1, 2, 3, 4],},
        // { text: "Nombre", value: "cliente.nombreCompleto" },
        // { text: "Espacio", value: "nombreEspacio", dataType: "select" },
        // { text: "Salon", value: "nombreSalon", dataType: "select" },
        // { text: "Adultos", value: "datos.nAdultos" },
        // { text: "Niños", value: "datos.nNinos" },
        // { text: "Total", value: "datos.tcos" },
        // { text: "Teléfono", value: "cliente.telefono" },
        // { text: "Email", value: "cliente.email" },
        // { text: "Estado", value: "estado", dataType: "select" },
      ]
        .filter(
          (h) =>
            (h.acceso == null || this.$root.acceso(h.acceso)) &&
            (h.tab == null ||
              [h.tab].flat().includes(this.tipo * 1) ||
              !this.tipo)
        )
        .map((header) => ({
          class: "text-no-wrap sticky-header",
          cellClass: "pa-1 text-no-wrap",
          filter: (value) =>
            perColumnFilter(
              value,
              this.inlineFilters[header.value],
              header.dataType
            ),
          dataType: "text",
          ...header,
        }));
    },
  },
  watch: {
    tipo(n) {
      this.getNominas(n);
    },
  },
  mounted() {
    this.getNominas();
  },
  methods: {
    parseDate,
    async getNominas() {
      this.loading = true;
      const { data } = await axios({
        method: "GET",
        url: "nominas/pendientes",
        params: { tipo: this.tipo || null, all: this.all },
      });

      this.nominas = data.map((m) => {
        m = JSON.parse(m.datos);
        return m;
      });
      this.loading = false;
    },
  },
};
</script>